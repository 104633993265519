<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>




<div class="page-header text-center" style="background-image: url('assets/img/TopHeaderAnimation.gif');">
    <div class="container">
        <h1 class="page-title" style="color: #ffecec;">Checkout</h1>
    </div>
</div>
<nav aria-label="breadcrumb" class="breadcrumb-nav mb-3">
    <div class="container">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.php">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Shop</a></li>
            <li class="breadcrumb-item active" aria-current="page">Checkout</li>
        </ol>
    </div><!-- End .container -->
</nav><!-- End .breadcrumb-nav -->



<!-- Start Checkout Area -->
<section class="checkout-area" style="padding-top: 20px;">
    <div class="container">
        <form>
            <div class="row">


                <div class="col-lg-8">

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card card-dashboard">
                                <div class="card-body">
                                    <h1 class="card-title">Billing Address</h1><!-- End .card-title -->
                                    <p> {{this.billingAddress.addressLineOne}} <br>
                                        {{this.billingAddress.addressLineTwo}}<br>
                                        {{this.billingAddress.addressLineThree}}<br>
                                        {{this.billingAddress.city}}<br>
                                        {{this.billingAddress.postalCode}}<br>

                                    </p>
                                    <a [routerLink]="['/account_details/addresses']" id="billing-address">Edit <i class="icon-edit"></i></a>


                                </div><!-- End .card-body -->
                            </div><!-- End .card-dashboard -->
                        </div><!-- End .col-lg-6 -->

                        <div class="col-lg-6">
                            <div class="card card-dashboard">
                                <div class="card-body">
                                    <h1 class="card-title">Shipping Address</h1><!-- End .card-title -->
                                    <p> {{this.shippingAddress.addressLineOne}} <br>
                                        {{this.shippingAddress.addressLineTwo}}<br>
                                        {{this.shippingAddress.addressLineThree}}<br>
                                        {{this.shippingAddress.city}}<br>
                                        {{this.shippingAddress.postalCode}}<br>

                                    </p>
                                    <a data-toggle="modal" data-target=".bd-example-modal-lg"
                                        id="shipping-address">Change <i class="las la-retweet"></i></a>



                                </div><!-- End .card-body -->
                            </div><!-- End .card-dashboard -->
                        </div><!-- End .col-lg-6 -->
                    </div>

                </div>







                <aside class="col-lg-4">
                    <div class="summary">
                        <h1 class="summary-title">Your Order</h1><!-- End .summary-title -->

                        <table class="table table-summary">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Total</th>
                                </tr>
                            </thead>

                            <tbody>


                                <tr *ngFor="let item of userCartDetailsList" >
                                    <td><a href="#">{{item.productName}}</a></td>
                                    <td>LKR {{item.price * item.qty  | number:'1.2-2' }}  </td>
                                </tr>
                                <tr class="summary-subtotal">
                                    <td>Subtotal:</td>
                                    <td> LKR {{subTotalData  | number:'1.2-2' }} </td>
                                </tr><!-- End .summary-subtotal -->
     
                                <tr>
                                    <td>Shipping:</td>
                                    <td>LKR {{shipping  | number:'1.2-2' }}</td>
                                </tr>
                                <tr class="summary-total">
                                    <td>Total:</td>
                                    <td>LKR {{allTotal_value  | number:'1.2-2' }}</td>
                                </tr><!-- End .summary-total -->
                            </tbody>
                        </table><!-- End .table table-summary -->

                        <div class="accordion-summary" id="accordion-payment">
                            <div class="card" style="border: none;" >
                                <div class="card-header" id="heading-5" style="border: none;" >
                                    <h2 class="card-title">
                                        <input type="radio" value="card" value="card" [(ngModel)]="selectedPaymentType" checked="" name="payment_mode" id="card">
                                        Card Pay
                                        <img src="assets/img/payments-summary.png" alt="payments cards">

                                    </h2>
                                </div><!-- End .card-header -->
                                <div id="collapse-5" class="collapse" aria-labelledby="heading-5"
                                    data-parent="#accordion-payment">
                                    <div class="card-body">
                                    </div><!-- End .card-body -->
                                </div><!-- End .collapse -->
                            </div><!-- End .card -->
                            <br>
                            <div class="card" style="border: none;">
                                <div class="card-header" id="heading-4" style="border: none;" >
                                    <h2 class="card-title">
                                        <input type="radio" value="genie" name="payment_mode"  value="genie" [(ngModel)]="selectedPaymentType" id="genie"> Genie Pay
                                        <span style="color: #977e7e; font-size: 11px; text-decoration: underline;">
                                             <span>
                                                <img src="assets/img/genie-logo.png" alt="payments cards">
                                            </span></span>
                                    </h2>
                                </div><!-- End .card-header -->
                                <div id="collapse-4" class="collapse" aria-labelledby="heading-4"
                                    data-parent="#accordion-payment">
                                    <div class="card-body">

                                    </div><!-- End .card-body -->
                                </div><!-- End .collapse -->
                            </div><!-- End .card -->
                        </div><!-- End .accordion -->


                        <hr>

                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input"  [(ngModel)]="termsAccepted" (change)="onTermsAcceptedChange($event)" id="payment-policy">
                            <label class="custom-control-label" style="margin: 10px;" for="payment-policy">
                              I Accept the <a target="_blank" href="terms-and-conditions.php">Terms and Conditions</a> *
                            </label>
                          </div>
                          
                          <button type="submit" id="placeOrderBtn" [disabled]="!termsAccepted" (click)="openGatway()" class="btn btn-outline-secondary" style="width: 100%;" >
                            <span *ngIf="termsAccepted" class="btn-text">Place Order</span>
                            <span *ngIf="!termsAccepted" class="btn-hover-text" id="button_hover_text">Please Accept the Terms and Conditions</span>
                          </button>
                          

                    </div><!-- End .summary -->
                </aside>
            </div>
        </form>
    </div>
</section>
<!-- End Checkout Area -->

<!--div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="create-an-account">
                                    <label class="form-check-label" for="create-an-account">Create an account?</label>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ship-different-address">
                                    <label class="form-check-label" for="ship-different-address">Ship to a different address?</label>
                                </div>
                            </div-->