<nav class="navbar navbar-expand-lg ftco_navbar ftco-navbar-light" id="ftco-navbar"
    style="background-image: linear-gradient(170deg, rgba(2, 85, 112, 1) 0%, rgba(14, 203, 218, 1) 61%, rgba(0, 255, 119, 1) 100%);">
    <div class="container">

     <table >
        <tr>
            <td> <a class="navbar-brand" href="index.html"><img style="height: 20px;" src="assets/img/sri-lanka.png"></a> </td>
            <td> <select class="form-select form-select-sm" style="width: 200px;">
                <option> Sri Lanka </option>
            </select> </td>
            <td> <span style="margin-left: 10px; color: white;">LKR</span> </td>
        </tr>
     </table>
            
            
        <div class="social-media order-lg-last nav-items">
            <ul class="navbar-nav ml-auto mr-md-3 n ">
                <li class="nav-item active"><a href="tel:+94766555550" class="nav-link"> <i class='bx bx-phone-call'></i> Call Now</a></li>
                <li class="nav-item"><a href="/about_us" class="nav-link"> <img src="assets/img/tedi.png" style="height: 25px;" >  About Us</a></li>
                <li class="nav-item"><a href="/contact_us" class="nav-link"> <i class='bx bx-envelope'></i> Contact Us</a></li>
                <!--li class="nav-item" *ngIf="token==null || token=='empty' "><a href="/login" class="nav-link"> <i class='bx bx-user'></i> Login</a></li>
                <li class="nav-item" *ngIf="token!=null && accShow=='show'"><a (click)="profile()" class="nav-link"> <i class='bx bx-user'></i> Account</a></li-->
                    <li class="nav-item" style="margin-right: 40px;cursor: pointer;"
                            *ngIf="token==null || token=='empty' "><a  class="nav-link"  data-bs-toggle="modal"
                                data-bs-target="#productsQuickView2" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}" (click)="showLogin()">
                                <div style="color: white;"><i class='bx bx-user'></i> Login</div>
                            </a></li>
                            

                            <li class="nav-item" style="margin-right: 20px;"  *ngIf="token!=null && accShow=='show'"><a (click)="profile()" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <div style="color: white;"><i class='bx bx-user'></i> Account</div>
                            </a></li>
            </ul>
        </div>

        
    
            <button class="navbar-toggler order-lg-last"  type="button" (click)="toggleDropdown()" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                Account
            </button>
            <div [ngClass]="{'collapse navbar-collapse dropdown-menu-right': true, 'show': isDropdownOpen}" id="ftco-nav" style="background-color: white;" >
                <ul class="navbar-nav ml-auto mr-md-3 navbar-toggler dropdown-menu-right" style="position: absolute; background-color: white;  right: 0;" >
                    <li class="nav-item"><a href="#" class="nav-link"><i class='bx bx-phone-call'></i> Call Now</a></li>
                    <li class="nav-item"><a href="#" class="nav-link"><img src="assets/img/tedi.png" style="height: 25px;"> About Us</a></li>
                    <li class="nav-item"><a href="#" class="nav-link"><i class='bx bx-envelope'></i> Contact Us</a></li>
                    <li class="nav-item"><a href="#" class="nav-link"><i class='bx bx-user'></i> Login</a></li>
                </ul>
            </div>


            
    
    </div>
</nav>

<div class="modal fade" id="productsQuickView2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: right;">
                        <button type="button" class="close2" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i class='bx bx-x' style="font-size:28px;"></i></span>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="login-tab" data-bs-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="true" style="font-weight: bold; color: #8ab657;">Sign In</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="register-tab" data-bs-toggle="tab" href="#register" role="tab" aria-controls="register" aria-selected="false" style="font-weight: bold;">Register</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <!-- Login Tab Content -->
                            <div class="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
                                <div class="login-form">
                                    <form #contactForm="ngForm" style="padding: 20px;">
                                        <div class="form-group">
                                            <label for="email">Username or email address *</label>
                                            <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter your email">
                                        </div>
                                        <div class="form-group">
                                            <label for="password">Password *</label>
                                            <input required minlength="3" maxlength="20" ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter your password">
                                        </div>
                                        <div class="form-group d-flex justify-content-between align-items-center">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="rememberMe">
                                                <label class="form-check-label" for="rememberMe">Remember Me</label>
                                            </div>
                                            <a (click)="submitLostPassword(email.value)" class="lost-your-password" style="color: #000;">Forgot Your Password?</a>
                                        </div>
                                        <button type="submit" (click)="loginToSystem(email.value, password.value)" class="btn btn-outline-success" style="width: 100%; margin-top: 10px;">LOG IN</button>
                                    </form>
                                </div>
                            </div>
                            <!-- Register Tab Content -->
                            <div class="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
                                <div class="register-form">
                                    <form #registerForm="ngForm" style="padding: 20px;">
                                        <div class="form-group">
                                            <label for="registerEmail">Enter Email *</label>
                                            <input required minlength="3" maxlength="50" ngModel name="registerEmail" type="email" #registerEmail="ngModel" class="form-control" id="registerEmail" placeholder="Enter your email">
                                        </div>
                                        <div class="form-group">
                                            <label for="registerPassword">Enter Password *</label>
                                            <input required minlength="3" maxlength="20" ngModel name="registerPassword" type="password" #registerPassword="ngModel" class="form-control" id="registerPassword" placeholder="Enter your password">
                                        </div>
                                        <div class="form-group">
                                            <label for="confirmPassword">Confirm Password *</label>
                                            <input required minlength="3" maxlength="20" ngModel name="confirmPassword" type="password" #confirmPassword="ngModel" class="form-control" id="confirmPassword" placeholder="Confirm your password">
                                        </div>
                                        <div class="form-group  justify-content-between align-items-center">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="rememberMe">
                                                <label class="form-check-label" for="rememberMe"> I hereby agree to  <a class="lost-your-password" style="color: #000; cursor: pointer;" routerLink="/terms_of_use" target="_blank" > Terms & Conditions</a> & <a class="lost-your-password" style="color: #000; cursor: pointer;" routerLink="/privacy_policy" target="_blank" > Privacy Policy</a>  </label>
                                            </div>
                                        </div>


                                        <button (click)="registerToSystem( registerEmail.value, registerPassword.value, confirmPassword.value)" type="submit" class="btn btn-outline-success" style="width: 100%; margin-top: 10px;">REGISTER</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>
            </div>
        </div>
    </div>
</div>




<!--nav class="navbar navbar-expand-lg ftco_navbar ftco-navbar-light" id="ftco-navbar"
    style="background-image: linear-gradient(170deg, rgba(2, 85, 112, 1) 0%, rgba(14, 203, 218, 1) 61%, rgba(0, 255, 119, 1) 100%);">
    <div class="container">

     <table >
        <tr>
            <td> <a class="navbar-brand" href="index.html"><img style="height: 20px;" src="assets/img/sri-lanka.png"></a> </td>
            <td> <select class="form-select form-select-sm" style="width: 200px;">
                <option> Sri Lanka </option>
            </select> </td>
            <td> <span style="margin-left: 10px; color: white;">LKR</span> </td>
        </tr>
     </table>
            
            
        <div class="social-media order-lg-last nav-items">
            <ul class="navbar-nav ml-auto mr-md-3 n ">
                <li class="nav-item active"><a href="tel:+94766555550" class="nav-link"> <i class='bx bx-phone-call'></i> Call Now</a></li>
                <li class="nav-item"><a href="/about_us" class="nav-link"> <img src="assets/img/tedi.png" style="height: 25px;" >  About Us</a></li>
                <li class="nav-item"><a href="/contact_us" class="nav-link"> <i class='bx bx-envelope'></i> Contact Us</a></li>
                <li class="nav-item" *ngIf="token==null || token=='empty' "><a href="/login" class="nav-link"> <i class='bx bx-user'></i> Login</a></li>
                <li class="nav-item" *ngIf="token!=null && accShow=='show'"><a (click)="profile()" class="nav-link"> <i class='bx bx-user'></i> Account</a></li>
            </ul>
        </div>

        
    
            <button class="navbar-toggler order-lg-last"  type="button" (click)="toggleDropdown()" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                Account
            </button>
            <div [ngClass]="{'collapse navbar-collapse dropdown-menu-right': true, 'show': isDropdownOpen}" id="ftco-nav" style="background-color: white;" >
                <ul class="navbar-nav ml-auto mr-md-3 navbar-toggler dropdown-menu-right" style="position: absolute; background-color: white;  right: 0;" >
                    <li class="nav-item"><a href="#" class="nav-link"><i class='bx bx-phone-call'></i> Call Now</a></li>
                    <li class="nav-item"><a href="#" class="nav-link"><img src="assets/img/tedi.png" style="height: 25px;"> About Us</a></li>
                    <li class="nav-item"><a href="#" class="nav-link"><i class='bx bx-envelope'></i> Contact Us</a></li>
                    <li class="nav-item"><a href="#" class="nav-link"><i class='bx bx-user'></i> Login</a></li>
                </ul>
            </div>


            
    
    </div>
</nav-->