<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<div class="page-header text-center" style="background-image: url('assets/img/TopHeaderAnimation.gif');">
    <div class="container">

    </div>
</div>
<nav aria-label="breadcrumb" class="breadcrumb-nav mb-3">
    <div class="container">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.php">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Shop</a></li>
            <li class="breadcrumb-item active" aria-current="page">Item Category</li>
        </ol>
    </div><!-- End .container -->
</nav><!-- End .breadcrumb-nav -->

<!-- Start Page Title Area -->
<!--div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
</div-->
<!-- End Page Title Area -->

<!-- Start Products Area -->
<section class="products-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area left-widget-area">
                    <!--div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div-->

                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul *ngFor="let category of categoryList">
                            <a routerLink="/shop/{{category.name}}">
                                <li>{{category.name}}<!--span class="post_count">{{category.count}}</span--></li>
                            </a>
                            <!--li><a routerLink="/shop-left-sidebar-1">Men</a><span class="post_count">(7)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Women</a><span class="post_count">(3)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Sofa</a><span class="post_count">(2)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Uncategorized</a><span class="post_count">(8)</span></li-->
                        </ul>
                    </div>

                    <!--div class="widget widget_colors">
                        <h3 class="widget-title">Filter By Color</h3>

                        <ul>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="gray">Gray</span>
                                    <span>Gray</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="green">Green</span>
                                    <span>Green</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="red">Red</span>
                                    <span>Red</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="black">Black</span>
                                    <span>Black</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="brown">Brown</span>
                                    <span>Brown</span>
                                </a>
                            </li>
                        </ul>
                    </div-->

                    <!--div class="widget widget_price_filter">
                        <h3 class="widget-title">Filter by Price</h3>

                        <div class="collection_filter_by_price">
                            <input class="js-range-of-price" type="text" data-min="0" data-max="1055" name="filter_by_price" data-step="10">
                        </div>
                    </div-->


                </aside>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="fiwan-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <div class="d-flex align-items-center" *ngIf="singleProductsBox.length>0">
                            <p>We found <span class="count">{{singleProductsBox.length}}</span> products available for
                                you</p>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="singleProductsBox.length==0">
                            <p>No Products Found in <b>{{category}}</b></p>
                        </div>

                    </div>

                    <div class="col-lg-6 col-md-6 ordering">
                        <!--div class="select-box">
                            <label>Sort By:</label>
                            <ngx-select-dropdown (searchChange)="searchChange($event)" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options"></ngx-select-dropdown>
                        </div-->
                    </div>
                </div>

                <div class="row" *ngIf="singleProductsBox.length>0">
                    <div *ngFor="let Content of singleProductsBox | paginate: { itemsPerPage: 8, currentPage: shopLeftSidebar }">

                        <div class="product product-list">
                            <div class="row">
                                <div class="col-6 col-lg-3">
                                    <figure class="product-media">
                                        <a routerLink="/product_details/{{Content.id}}" >
                                            <img [src]="Content.productImage1"
                                                style="height: 100%; width: 100%; object-fit: contain; background-color: #ffffff;"
                                                id="image-24" alt="Product image" class="product-image">
                                        </a>
                                    </figure><!-- End .product-media -->
                                </div><!-- End .col-sm-6 col-lg-3 -->

                                
                                <div class="col-6 col-lg-3 order-lg-last" style="vertical-align: middle;" >
                                    <div class="product-list-action"  style="vertical-align: middle;" >
                                        
                                        <div class="product-price"> LKR {{ Content.productPrice | number:'1.2-2' }}</div><!-- End .product-price -->
                                        <form method="POST"  >
                                            <input type="hidden" name="product_id" value="24">
                                            <!--input type="hidden" name="qty" id="qty" value="1"-->
                                            <button routerLink="/product_details/{{Content.id}}"   type="submit"
                                                style="color: white; background-color: #329d7f; padding: 1rem 1.5rem; max-width: 100%; color:white; border: 1px solid white;"
                                                class="btn-product"> <i class="icon-arrow-right"></i> <span>View
                                                    Product</span></button>
                                        </form>
                                    </div><!-- End .product-list-action -->
                                </div><!-- End .col-sm-6 col-lg-3 -->
                                
                                <div class="col-lg-6">
                                    <div class="product-body product-action-inner">
                       
                                        <!--div class="product-cat">
                                            <a routerLink="/product_details/{{Content.id}}" >{{Content.productCompany}}</a>
                                        </div--><!-- End .product-cat -->
                                        <h2 ><a href="product.php?id=24">{{Content.productName}}</a></h2><!-- End .product-title -->
                                        <div class="product-content">
                                            <div>
                                                <div   [innerHTML]="Content.simpleDescription"></div>
                                            </div>
                                        </div><!-- End .product-content -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .col-lg-6 -->



                            </div><!-- End .row -->
                        </div>

                        <hr>


                    </div>

                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls (pageChange)="shopLeftSidebar = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Top Selling Products-->
<!--div class="products-area ptb-100">
    <app-popular-products></app-popular-products>
</div-->
<!-- End Products Area -->