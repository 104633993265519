<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>



<div class="page-header text-center" style="background-image: url('assets/img/TopHeaderAnimation.gif');">
    <div class="container">
        <h1 class="page-title" style="color: #ffecec;">My Account<span style="color: #ffecec;">Shop</span></h1>
    </div><!-- End .container -->
</div><!-- End .page-header -->
<nav aria-label="breadcrumb" class="breadcrumb-nav mb-3">
    <div class="container">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.php">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Shop</a></li>
            <li class="breadcrumb-item active" aria-current="page">My Account</li>
        </ol>
    </div><!-- End .container -->
</nav><!-- End .breadcrumb-nav -->

<div class="container">

    <div class="row" >


        <aside class="col-md-4 col-lg-3">
            <ul class="nav nav-dashboard flex-column mb-3 mb-md-0" role="tablist">
                <li class="nav-item">
                    <a  routerLink="/account_details/orders" class="nav-link" 
                    [ngClass]="{'active': activeLink === 'orders'}"
                    (click)="setActive('orders')"
                     id="tab-dashboard-link" data-toggle="tab" role="tab" aria-controls="tab-dashboard" aria-selected="true">Orders</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/account_details/pre-orders" class="nav-link"
                    [ngClass]="{'active': activeLink === 'pre-orders'}"
                    (click)="setActive('pre-orders')"
                     id="tab-orders-link" data-toggle="tab"  role="tab" aria-controls="tab-orders" aria-selected="false">Pre Orders</a>
                </li>

                <li class="nav-item">
                    <a routerLink="/account_details/addresses" class="nav-link" 
                    [ngClass]="{'active': activeLink === 'addresses'}"
                    (click)="setActive('addresses')"
                    id="tab-address-link" data-toggle="tab"  role="tab" aria-controls="tab-address" aria-selected="false">Adresses</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/account_details/account-details-info" class="nav-link" 
                    [ngClass]="{'active': activeLink === 'account-details-info'}"
                    (click)="setActive('account-details-info')"
                    id="tab-account-link" data-toggle="tab" role="tab" aria-controls="tab-account" aria-selected="false">Account Details</a>
                </li>

                <li class="nav-item">
                    <a routerLink="/account_details/gift-card" class="nav-link"
                    [ngClass]="{'active': activeLink === 'gift-card'}"
          (click)="setActive('gift-card')"
                     id="tab-account-link" data-toggle="tab"  role="tab" aria-controls="tab-account" aria-selected="false">Gift Card</a>
                </li>

                <li class="nav-item">
                    <a routerLink="/account_details/change-password"  class="nav-link" 
                    [ngClass]="{'active': activeLink === 'change-password'}"
                    (click)="setActive('change-password')"
                    id="tab-password-link" data-toggle="tab"  role="tab" aria-controls="tab-password" aria-selected="false">Change Password</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="service/controller/usesr_controller.php/singout">Sign Out</a>
                </li>
            </ul>
        </aside><!-- End .col-lg-3 -->
        <div class="col-md-8 col-lg-9">
            <div class="tab-content">
                <router-outlet></router-outlet> <!-- Child routes will be loaded here -->
            </div>
        </div>
    </div>

</div>


